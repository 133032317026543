import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBarComponent from './components/NavBarComponent';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Residency from './components/Residency';
import Commercial from './components/Commercial';
import Interior from './components/Interior';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const MainApp = () => {
  return (
    <Router>
      <NavBarComponent />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/Commercial" element={<Commercial />} />
        <Route path="/Residency" element={<Residency />} />
        <Route path="/Interior" element={<Interior />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
};

ReactDOM.render(<MainApp />, document.getElementById('root'));
