import React from 'react';
import './style.css'
import Footer from './Footer';

const servicesData = [
  {   
      title: 'Interior Work',
      description: 'We specialize in creating beautiful and functional interior spaces. From design to execution, we ensure every detail is perfect.',
    },
    {
      title: 'Plan & Architect',
      description: 'Our planning and architectural services provide you with innovative and efficient designs. We turn your ideas into reality with precision and expertise.',
    },
    {
      title: 'Documentation Work',
      description: 'We handle all your documentation needs efficiently. Our team ensures that all necessary permits and documents are in place for a smooth project execution.',
    },
];

const ServiceCol = ({ title, description }) => (
  <div className="service-cols">
    <h3 className='services-p'>{title}</h3>
    <p className='services-p'>{description}</p>
  </div>
);

const Services = () => (
  <>
  <section className="SERVICES">
    <h1>Services We Offer</h1>
    <p>We provide high-quality construction services, tailored to meet your needs. Our team of experts is dedicated to delivering exceptional results on every project.</p>
    <div className="row">
      {servicesData.map((service, index) => (
        <ServiceCol key={index} title={service.title} description={service.description} />
      ))}
    </div>
    <a href="/contact"  class="visit-btn">Visit Us To Know More</a>
  </section>
  <Footer />
  </>
);

export default Services;