import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

const CounterSection = () => {
  return (
    <>
    <div className='Exp'>
    <div className='Experience'>
      <h2>&nbsp;&nbsp;&nbsp;&nbsp; 100+<br/>&nbsp;&nbsp;Projects</h2>    
      </div>
      <div className='Experience'>
      <h2> &nbsp;14 Years <br/>Experience</h2>      
      </div>
      <div className='Experience'>
      <h2>&nbsp;&nbsp;&nbsp; 100+<br/>&nbsp;&nbsp;Clients</h2>    
      </div>      
    </div>
    </>
  );
};

export default CounterSection;
