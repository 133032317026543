import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css'; // Ensure you import the CSS file
import slide1 from './IMG/slidebg1.jpg';
import slide2 from './IMG/Interior1.jpg';
import slide3 from './IMG/Interior4.jpg';
// import slide4 from './IMG/slidebg4.jpg';
import slide4 from './IMG/slidebg5.jpg';
// import slide6 from './IMG/slidebg6.jpg';
// import slidebg7 from './IMG/slidebg7.jpg';
import CounterSection from './CounterSection';
import Services from './Services';




const Home = () => {
  return (
    <>
    <div className="home-container">
      <div className="carousel-container">
        <Carousel interval={2500} pause fade/* 3000ms = 3s interval */
          controls={false} /* Hide prev/next buttons */
          indicators={false} /* Hide slide indicators */>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={slide1}
              alt="First slide"
            />
            <Carousel.Caption> 
            <h3 style={{color:'black' ,fontFamily: 'Agency FB, sans-serif', fontStyle: 'italic'}}><span style={{ color: 'red',fontFamily: 'Agency FB, sans-serif', fontStyle: 'italic' }}>ABU</span> Construction</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={slide2}
              alt="Second slide"
            />
            <Carousel.Caption>
            <h3 style={{color:'black' ,fontFamily: 'Agency FB, sans-serif', fontStyle: 'italic'}}><span style={{ color: 'red',fontFamily: 'Agency FB, sans-serif', fontStyle: 'italic' }}>ABU</span> Construction</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-10"
              src={slide3}
              alt="Third slide"
            />
            <Carousel.Caption>
            <h3 style={{color:'black' ,fontFamily: 'Agency FB, sans-serif', fontStyle: 'italic'}}><span style={{ color: 'red',fontFamily: 'Agency FB, sans-serif', fontStyle: 'italic' }}>ABU</span> Construction</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={slide4}
              alt="Fourth slide"
            />
            <Carousel.Caption>
            <h3 style={{color:'black' ,fontFamily: 'Agency FB, sans-serif', fontStyle: 'italic'}}><span style={{ color: 'red',fontFamily: 'Agency FB, sans-serif', fontStyle: 'italic' }}>ABU</span> Construction</h3>
            </Carousel.Caption>
          </Carousel.Item>       
        </Carousel>
      </div>
      <div className="content-container">
    {/* Add your other content here */}
    <h1 className="title">Welcome to <span className="highlight">ABU</span> Construction</h1>
    <p className="description" style={{ fontFamily:'Agency FB, sans-serif' }}>
        <span className="highlight">ABU</span> Construction, your trusted partner in construction excellence. We specialize in delivering top-quality construction services, from innovative design to meticulous execution. Our team of experienced professionals is committed to turning your vision into reality with precision and care. Explore our projects, discover our services, and see why <span className="highlight">ABU</span> Construction is the preferred choice for all your construction needs. Your dream project starts here!<br/>
        We provide high-quality construction services, tailored to meet your needs. Our team of experts is dedicated to delivering exceptional results on every project.<br/>
        At our construction company, we pride ourselves on delivering top-notch construction services to our clients. With a dedicated team of professionals, we ensure that every project is handled with the utmost care and precision. Our commitment to excellence is reflected in our attention to detail and our pursuit of innovation in every project we undertake.
    </p>
</div>

    </div><br/><br/>
    <div className="why-choose-us">
      <h2>Why Choose Us</h2>
      <div className="reasons">
        <div className="reason">
          <h3>Expertise</h3>
          <p>
            Our team of professionals has extensive experience and knowledge in the construction industry, ensuring top-quality results.
          </p>
        </div>
        <div className="reason">
          <h3>Quality</h3>
          <p>
            We use the highest quality materials and the latest construction techniques to deliver exceptional results.
          </p>
        </div>
        <div className="reason">
          <h3>Customer Satisfaction</h3>
          <p>
            We prioritize our clients' needs and work closely with them to ensure complete satisfaction with our services.
          </p>
        </div>
      </div>
    </div>
      <CounterSection />
      <Services />      
    </>
  );
};

export default Home;
