import React from 'react';
import Footer from './Footer';
import './style.css';
import CounterSection from './CounterSection';


const About = () => (
  <>
  <section className="about-page">
    <div className="about-content">      
      <div className="about-text">
        <h1>About Us</h1>
        <p>
          We are a leading construction company dedicated to providing high-quality services. Our experienced team is committed to delivering exceptional results for all your construction needs. From residential to commercial projects, we bring expertise and attention to detail to every job.
        </p>
        <p>
          Our mission is to build lasting relationships with our clients by exceeding their expectations and delivering superior results. We use the latest technology and techniques to ensure that every project is completed on time, within budget, and to the highest standards.
        </p>
      </div>
    </div>
  </section><br/>
  <div className="why-choose-us">
      <h2>Why Choose Us</h2>
      <div className="reasons">
        <div className="reason">
          <h3>Expertise</h3>
          <p>
            Our team of professionals has extensive experience and knowledge in the construction industry, ensuring top-quality results.
          </p>
        </div>
        <div className="reason">
          <h3>Quality</h3>
          <p>
            We use the highest quality materials and the latest construction techniques to deliver exceptional results.
          </p>
        </div>
        <div className="reason">
          <h3>Customer Satisfaction</h3>
          <p>
            We prioritize our clients' needs and work closely with them to ensure complete satisfaction with our services.
          </p>
        </div>
      </div>
      </div>
    <CounterSection/>
    <Footer />
  </>
);

export default About;
