import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './style.css'; // Create a CSS file for custom styles
import c1 from './IMG/c1.jpg';
import c2 from './IMG/c2.jpg';
import c3 from './IMG/c3.jpg';
import c4 from './IMG/c5.jpg';
import c5 from './IMG/c6.jpg';
import c6 from './IMG/c4.jpg';
import Footer from './Footer';



const Commercial = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [
    { original: c1,thumbnail: c1},
    { original: c2, thumbnail: c2 },
    { original: c3, thumbnail: c3 },
    { original: c4, thumbnail: c4 },
    { original: c5, thumbnail: c5 },
    { original: c6, thumbnail: c6 }
  ];

  const openGallery = (index) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  const closeGallery = () => {
    setIsOpen(false);
  };

  return (
  <>
    <div className="commercial">
      <h1 style={{ fontFamily: 'Agency FB, sans-serif', fontStyle: 'italic'  }}>Commercial</h1>
      <div className="image-grid">
        {images.map((image, index) => (
          <div key={index} className="image-item" onClick={() => openGallery(index)}>
            <img src={image.thumbnail} alt={`Thumbnail ${index + 1}`} />
          </div>
        ))}
      </div>
      
      {isOpen && (
        <div className="gallery-overlay" onClick={closeGallery}>
          <div className="gallery-container" onClick={(e) => e.stopPropagation()}>
            <ImageGallery
              items={images}
              startIndex={currentImageIndex}
              showThumbnails={false}
              showFullscreenButton={false}
              showPlayButton={false}
              onSlide={(index) => setCurrentImageIndex(index)}
            />
          </div>
        </div>
      )}
    </div>
    <Footer/>
  </>
  );
};

export default Commercial;
