import React, { useState } from 'react';
import Footer from './Footer';
import 'animate.css';
import Swal from 'sweetalert2';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    project: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    data.append("access_key", "cc889d5e-5015-4ddb-aa9c-d8b209880e00");

    const object = Object.fromEntries(data);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      Swal.fire({
        title: "Success",
        text: "Message Sent Successfully!",
        icon: "success"
      });
    }
  };

  const constructWhatsAppUrl = () => {
    const baseUrl = 'https://wa.me/919600770061';
    const message = `
      Name: ${formData.name},
      Email: ${formData.email},
      Phone: ${formData.phone},
      Project: ${formData.project},
      Message: ${formData.message}.
    `;
    return `${baseUrl}?text=${encodeURIComponent(message)}`;
  };

  const sendViaWhatsApp = (event) => {
    event.preventDefault();
    const url = constructWhatsAppUrl();
    window.open(url, '_blank');
    setFormData({
      name: '',
      email: '',
      phone: '',
      project: '',
      message: '',
    });
  };

  return (
    <>
      <div>
        {/* Header Start */}
        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5" style={{ maxWidth: '900px' }}>
            <h4 className="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">Contact Us</h4>
            <ol className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
              <li className="breadcrumb-item"><a href="/">Home</a></li>          
              <li className="breadcrumb-item active text-secondary">Contact</li>
            </ol>
          </div>
        </div>
        {/* Header End */}

        {/* Contact Start */}
        <div className="container-fluid contact-page bg-light py-5">
          <div className="container form">
            <div className="row g-5 mb-5">
              <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0.2s">
                <div className="text-center mx-auto pb-5" style={{ maxWidth: '800px' }}>
                  <p className="text-uppercase text-secondary fs-5 mb-0">Let’s Connect</p>
                  <h2 className="display-4 text-capitalize mb-3">Send Your Message</h2>
                </div>
                <form onSubmit={onSubmit} >
                  <div className="row g-3">
                    <div className="col-lg-12 col-xl-6">
                      <div className="form-floating border border-secondary">
                        <input type="text" className="form-control" name="name" placeholder="Your Name" required value={formData.name} onChange={handleChange} />
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-6">
                      <div className="form-floating border border-secondary">
                        <input type="email" className="form-control" name="email" placeholder="Your Email"  value={formData.email} onChange={handleChange} />
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-6">
                      <div className="form-floating border border-secondary">
                        <input type="tel" className="form-control" name="phone" placeholder="Your Phone" required value={formData.phone} onChange={handleChange} />
                        <label htmlFor="phone">Your Phone</label>
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-6">
                      <div className="form-floating border border-secondary">
                        <input type="text" className="form-control" name="project" placeholder="Your Project"  value={formData.project} onChange={handleChange} />
                        <label htmlFor="project">Your Project</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating border border-secondary">
                        <textarea className="form-control" name="message" placeholder="Leave a message here" style={{ height: '160px' }} required value={formData.message} onChange={handleChange}></textarea>
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-success w-100 py-3" onClick={sendViaWhatsApp}>
                        Send via WhatsApp
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-6 wow fadeInRight" data-wow-delay="0.4s">
                <div className="contact-map h-100 w-100">
                  <iframe
                    className="h-100 w-100"
                    style={{ height: '500px' }}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d945.4991217848481!2d79.37481016955633!3d10.33394819936183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00030003882ae9%3A0x6c863dc346fc085a!2sAbu%20constructions!5e1!3m2!1sen!2sin!4v1722261946548!5m2!1sen!2sin" 
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Google Maps"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Contact End */}
      </div>
      <Footer />
    </>
  );
};

export default Contact;



// import React, { useState } from 'react';
// import Footer from './Footer';
// import 'animate.css';
// import Swal from 'sweetalert2';

// const Contact = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     project: '',
//     message: '',
//   });

//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const onSubmit = async (event) => {
//     event.preventDefault();
//     const data = new FormData(event.target);

//     data.append("access_key", "78d9d326-1853-4a38-8ffb-8df319b2b245");

//     const object = Object.fromEntries(data);
//     const json = JSON.stringify(object);

//     const res = await fetch("https://api.web3forms.com/submit", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json"
//       },
//       body: json
//     }).then((res) => res.json());

//     if (res.success) {
//       Swal.fire({
//         title: "Success",
//         text: "Message Sent Successfully!",
//         icon: "success"
//       });

//       // Construct WhatsApp URL and navigate to it
//       const whatsappUrl = constructWhatsAppUrl();
//       window.location.href = whatsappUrl;
//     }
//   };

//   const constructWhatsAppUrl = () => {
//     const baseUrl = 'https://wa.me/919629948681';
//     const message = `
//       Name: ${formData.name}%0A
//       Email: ${formData.email}%0A
//       Phone: ${formData.phone}%0A
//       Project: ${formData.project}%0A
//       Message: ${formData.message}
//     `;
//     return `${baseUrl}?text=${encodeURIComponent(message)}`;
//   };

//   return (
//     <>
//       <div>
//         {/* Header Start */}
//         <div className="container-fluid bg-breadcrumb">
//           <div className="container text-center py-5" style={{ maxWidth: '900px' }}>
//             <h4 className="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">Contact Us</h4>
//             <ol className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
//               <li className="breadcrumb-item"><a href="/">Home</a></li>          
//               <li className="breadcrumb-item active text-secondary">Contact</li>
//             </ol>
//           </div>
//         </div>
//         {/* Header End */}

//         {/* Contact Start */}
//         <div className="container-fluid contact-page bg-light py-5">
//           <div className="container form">
//             <div className="row g-5 mb-5">
//               <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0.2s">
//                 <div className="text-center mx-auto pb-5" style={{ maxWidth: '800px' }}>
//                   <p className="text-uppercase text-secondary fs-5 mb-0">Let’s Connect</p>
//                   <h2 className="display-4 text-capitalize mb-3">Send Your Message</h2>
//                 </div>
//                 <form onSubmit={onSubmit}>
//                   <div className="row g-3">
//                     <div className="col-lg-12 col-xl-6">
//                       <div className="form-floating border border-secondary">
//                         <input type="text" className="form-control" name="name" placeholder="Your Name" required onChange={handleChange} />
//                         <label htmlFor="name">Your Name</label>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 col-xl-6">
//                       <div className="form-floating border border-secondary">
//                         <input type="email" className="form-control" name="email" placeholder="Your Email" required onChange={handleChange} />
//                         <label htmlFor="email">Your Email</label>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 col-xl-6">
//                       <div className="form-floating border border-secondary">
//                         <input type="tel" className="form-control" name="phone" placeholder="Your Phone" required onChange={handleChange} />
//                         <label htmlFor="phone">Your Phone</label>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 col-xl-6">
//                       <div className="form-floating border border-secondary">
//                         <input type="text" className="form-control" name="project" placeholder="Your Project" required onChange={handleChange} />
//                         <label htmlFor="project">Your Project</label>
//                       </div>
//                     </div>
//                     <div className="col-12">
//                       <div className="form-floating border border-secondary">
//                         <textarea className="form-control" name="message" placeholder="Leave a message here" style={{ height: '160px' }} required onChange={handleChange}></textarea>
//                         <label htmlFor="message">Message</label>
//                       </div>
//                     </div>
//                     <div className="col-12">
//                       <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
//                     </div>
//                   </div>
//                 </form>
//               </div>
//               <div className="col-lg-6 wow fadeInRight" data-wow-delay="0.4s">
//                 <div className="contact-map h-100 w-100">
//                   <iframe
//                     className="h-100 w-100"
//                     style={{ height: '500px' }}
//                     src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3925.0032143397734!2d79.37596927487623!3d10.341627189781931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b000208715d3d11%3A0xcf7845ab5a66d482!2sAbu%20Construction!5e0!3m2!1sen!2sin!4v1721023815648!5m2!1sen!2sin" 
//                     loading="lazy"
//                     referrerPolicy="no-referrer-when-downgrade"
//                     title="Google Maps"
//                   ></iframe>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* Contact End */}
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Contact;



// import React from 'react';
// import Footer from './Footer';
// import 'animate.css';
// import Swal from 'sweetalert2'

// const onSubmit = async (event) => {
//   event.preventDefault();
//   const formData = new FormData(event.target);

//   formData.append("access_key", "78d9d326-1853-4a38-8ffb-8df319b2b245");

//   const object = Object.fromEntries(formData);
//   const json = JSON.stringify(object);

//   const res = await fetch("https://api.web3forms.com/submit", {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json"
//     },
//     body: json
//   }).then((res) => res.json());

//   if (res.success) {
//     Swal.fire({
//       title: "Success",
//       text: "Message Sent Success !",
//       icon: "success"
//     });
//   }
// };

// const Contact = () => (
//   <>
//   <div>
//     {/* Header Start */}
//     <div className="container-fluid bg-breadcrumb">
//       <div className="container text-center py-5" style={{ maxWidth: '900px' }}>
//         <h4 className="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">Contact Us</h4>
//         <ol className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
//           <li className="breadcrumb-item"><a href="/">Home</a></li>          
//           <li className="breadcrumb-item active text-secondary">Contact</li>
//         </ol>
//       </div>
//     </div>
//     {/* Header End */}

//     {/* Contact Start */}
//     <div className="container-fluid contact-page bg-light py-5">
//       <div className="container form">
//         <div className="row g-5 mb-5">
//           <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0.2s">
//             <div className="text-center mx-auto pb-5" style={{ maxWidth: '800px' }}>
//               <p className="text-uppercase text-secondary fs-5 mb-0">Let’s Connect</p>
//               <h2 className="display-4 text-capitalize mb-3">Send Your Message</h2>
//             </div>
//             <form onSubmit={onSubmit} >
//               <div className="row g-3">
//                 <div className="col-lg-12 col-xl-6">
//                   <div className="form-floating border border-secondary">
//                     <input type="text" className="form-control" id="name" placeholder="Your Name" required />
//                     <label htmlFor="name">Your Name</label>
//                   </div>
//                 </div>
//                 <div className="col-lg-12 col-xl-6">
//                   <div className="form-floating border border-secondary">
//                     <input type="email" className="form-control" name="email" placeholder="Your Email" required />
//                     <label htmlFor="email">Your Email</label>
//                   </div>
//                 </div>
//                 <div className="col-lg-12 col-xl-6">
//                   <div className="form-floating border border-secondary">
//                     <input type="phone" className="form-control" name="phone" placeholder="Phone" required />
//                     <label htmlFor="phone">Your Phone</label>
//                   </div>
//                 </div>
//                 <div className="col-lg-12 col-xl-6">
//                   <div className="form-floating border border-secondary">
//                     <input type="text" className="form-control" name="project" placeholder="Project" required/>
//                     <label htmlFor="project">Your Project</label>
//                   </div>
//                 </div>
                
//                 <div className="col-12">
//                   <div className="form-floating border border-secondary">
//                     <textarea className="form-control" placeholder="Leave a message here" name="message" style={{ height: '160px' }}></textarea>
//                     <label htmlFor="message"  required>Message</label>
//                   </div>
//                 </div>
//                 <div className="col-12">
//                   <button className="btn btn-primary w-100 py-3">Send Message</button>
//                 </div>
                
//               </div>
//             </form>
//           </div>
//           <div className="col-lg-6 wow fadeInRight" data-wow-delay="0.4s">
//             <div className="contact-map h-100 w-100">
//               <iframe
//                 className="h-100 w-100"
//                 style={{ height: '500px' }}
//                 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3925.0032143397734!2d79.37596927487623!3d10.341627189781931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b000208715d3d11%3A0xcf7845ab5a66d482!2sAbu%20Construction!5e0!3m2!1sen!2sin!4v1721023815648!5m2!1sen!2sin" 
//                 loading="lazy"
//                 referrerPolicy="no-referrer-when-downgrade"
//                 title="Google Maps"
//               ></iframe>
              
//             </div>
//           </div>
//         </div>
        
//       </div>
//     </div>
//     {/* Contact End */}
//   </div>
//   <Footer/>
//   </>
// );

// export default Contact;
